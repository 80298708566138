.display{
    grid-column: span 4;
    background-color: rgba(255, 155, 68, 0.2);

    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px;
    font-size: 2.1em;
    overflow: hidden;
}
