/*Criando variavel em css tem que ter o --*/
:root{
    /* --bg-button: #fff; */
    --border-button: solid 1px #888;
}

.button {
    font-size: 1.4em;
    background-color: var(--bg-button);
    border: none;
    color: #bbc4cc;
    border-right: var(--border-button);
    border-bottom: var(--border-button);
    outline: none;
}

.button:active{
    background-color: #bbc4cc;
}

.button.double{
    grid-column: span 2;
}

.button.triple{
    grid-column: span 3;
}

.button.operation{
    background-color: #ff9b44;
    color: #fff;
}

.button.operation:active{
    background-color: #fa8231cc;
}