.calculator{
    /* width: 235; */
    border-radius: 5px;
    overflow: hidden;
   height: fit-content;
    
    display: grid;
    grid-template-columns: repeat(4,25%);
    grid-template-rows: 1fr 60px 60px 60px 60px 60px;
}
